<!--团队设置：团队信息-->
<template>
  <div class="page-container">
    <div class="section">
      <div class="app-container">
        <!--页头-->
        <div class="page-header">
          <div class="ph-left">
            <!--页面标题-->
            <h2 class="page-title">团队信息</h2>
          </div>
          <div>
            <el-button
              v-if="managePermission"
              size="small"
              type="primary"
              icon="el-icon-upload2"
              @click="uploadLogo"
            >
              Logo
            </el-button>
          </div>
        </div>

        <div class="form-box" style="max-width: 900px">
          <el-form
            ref="info"
            :model="info"
            label-width="100px"
            label-position="left"
            :disabled="!managePermission"
          >
            <el-form-item v-show="false" label="ID">
              <el-input
                v-model="info.id"
                clearable
                :placeholder="info.id"
                :disabled="true"
                size="medium"
              />
            </el-form-item>
            <!--企业名称-->
            <el-form-item label="企业名称">
              <el-input
                v-model="info.cName"
                clearable
                :placeholder="info.cName"
                size="medium"
                disabled="disabled"
              />
            </el-form-item>
            <!--企业名称-->
            <!-- <el-form-item label="企业编码">
              <el-input
                v-model="info.teamCode"
                clearable
                :placeholder="info.teamCode"
                size="medium"
                disabled="disabled"
              />
            </el-form-item> -->
            <!--企业地址-->
            <el-form-item label="企业地址">
              <div class="display-flex">
                <!--省-->
                <el-select
                  v-model="info.province"
                  placeholder="省"
                  size="medium"
                  class="flex-item mr-5"
                  @change="changeCitys"
                >
                  <el-option
                    v-for="item in provinceOpts"
                    :key="item.provinceid"
                    :label="item.province"
                    :value="item.provinceid"
                  />
                </el-select>
                <!--市-->
                <el-select
                  v-model="info.city"
                  placeholder="市"
                  size="medium"
                  class="flex-item mr-5"
                  @change="changeAreas"
                >
                  <el-option
                    v-for="item in cityOpts"
                    :key="item.cityid"
                    :label="item.city"
                    :value="item.cityid"
                  />
                </el-select>
                <!--区-->
                <el-select
                  v-model="info.area"
                  placeholder="区"
                  size="medium"
                  class="flex-item"
                  @change="showArea"
                >
                  <el-option
                    v-for="item in areaOpts"
                    :key="item.areaid"
                    :label="item.area"
                    :value="item.areaid"
                  />
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="工作周期" >
              <div style="display:flex;">
                <el-input
                    disabled
                    v-model="info.time"
                    clearable
                    size="medium"
                    class="input-item"
                >
                  <span slot="prefix" style="display: inline-block;margin-left: 0px">昨日</span>
                </el-input>
                <div style="padding: 0 15px">
                  ~
                </div>
                <el-time-select
                    @change="handle"
                    v-model="info.time"
                    :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:00'
                }">
                </el-time-select>
              </div>
            </el-form-item>
            <!--详细地址-->
            <el-form-item label="详细地址">
              <el-input
                v-model="info.address"
                clearable
                :placeholder="info.address"
                size="medium"
              />
            </el-form-item>
            <!--企业邮箱-->
            <el-form-item label="企业邮箱">
              <el-input v-model="info.email" clearable :placeholder="info.email" size="medium" />
            </el-form-item>
            <!--企业介绍-->
            <el-form-item label="企业介绍">
              <el-input
                v-model="info.profile"
                clearable
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
              />
            </el-form-item>
            <!--提交按钮-->
            <el-form-item>
              <el-button
                v-if="managePermission"
                :loading="modifyLoading"
                type="primary"
                size="medium"
                icon="el-icon-check"
                @click.native.prevent="modifyTeamInfo"
              >
                提交
              </el-button>
            </el-form-item>
          </el-form>
          <!--弹出框-->
          <el-dialog
            v-if="visible.logoDialog"
            title="上传Logo"
            :visible.sync="visible.logoDialog"
            :modal-append-to-body="false"
            :append-to-body="false"
            :before-close="handleClose"
          >
            <template>
              <!--上传文件-->
              <el-upload
                ref="upload"
                class="text-center"
                drag
                action="#"
                :limit="1"
                :auto-upload="false"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :http-request="uploadFile"
                :file-list="entity.files"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  将Logo拖到此处，或
                  <em>点击上传</em>
                </div>
                <div slot="tip" class="el-upload__tip">
                  只能上传
                  <b>jpg/png</b>
                  图片文件，且不超过500kb
                </div>
              </el-upload>
            </template>
            <div slot="footer" class="dialog-footer">
              <el-button
                v-if="managePermission"
                :loading="modifyLoading"
                size="small"
                type="primary"
                @click="uploadInvoice"
              >
                确认上传
              </el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { getInfo, modify, getInitCities, getInitAreas, uploadLogo } from '@/api/team'
// import { getProvince, getCity, getArea } from '@/api/addr'
import API from '@/api';
// import { mapGetters } from 'vuex'
// import { manageOauth } from '@/utils/validate';
import {
  manageOauth, checkTeamName, checkDetailAddress, isEmail, checkTeamIntro,
} from '@/utils/validate';
// import i18n from '@/i18n/index'

export default {
  data() {
    return {
      visible: {
        logoDialog: false,
      },
      entity: {
        files: [],
      },
      info: {
        id: '',
        cName: '',
        teamCode: '',
        province: '',
        city: '',
        area: '',
        address: '',
        email: '',
        profile: '',
        time: '08:00'
      },
      time: '',
      modifyLoading: false,
      provinceOpts: [],
      cityOpts: [],
      areaOpts: [],
    };
  },
  computed: {
    // ...mapGetters(['buttons']),
    // viewPermission: {
    //   get: function() {
    //     return viewOauth('team_view', this.buttons)
    //   }
    // },
    managePermission: {
      get() {
        return manageOauth('team_operate', this.buttons);
      },
    },
  },
  created() {
    API.userLog({ code: 'webTeamInfo' }).then(() => {
    });
    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons;
    } else {
      this.buttons = sessionStorage.getItem('buttons');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons));
    });
  },
  mounted() {
    this.getInfo();
    this.getInitProvinces();
    this.getInitCities();
    this.getInitAreas();
  },
  //parseInt
  methods: {
    handle(val) {
      this.time = val
    },
    getInfo() {
      API.getInfo().then((response) => {
        this.info = response.message.data;
        this.info.time = response.message.data.time;
        // if (parseInt(this.info.time) < 10) {
        //   this.info.time = '0'+ this.info.time + ':00'
        // } else if (parseInt(this.info.time) >= 10) {
        //   this.info.time = this.info.time + ':00'
        // }
      });
    },
    getInitProvinces() {
      API.getProvince().then((response) => {
        this.provinceOpts = response.message.data;
      });
    },
    getInitCities() {
      API.getInitCities().then((response) => {
        this.cityOpts = response.message.data;
      });
    },
    getInitAreas() {
      API.getInitAreas().then((response) => {
        this.areaOpts = response.message.data;
      });
    },
    changeCitys(id) {
      this.info.city = '';
      this.info.area = '';
      this.areaOpts = [];
      API.getCity({ id }).then((response) => {
        this.cityOpts = response.message.data;
      });
    },
    changeAreas(id) {
      console.log(id);
      this.info.area = '';
      API.getArea({ id }).then((response) => {
        this.areaOpts = response.message.data;
      });
    },
    showArea() {
      this.$forceUpdate();
    },
    modifyTeamInfo() {
      if (!checkTeamName(this.info.cName)) {
        this.$message.error('请输入2-50个字符的企业名称');
        return false;
      }
      if (!this.info.time) {
        this.$message.error('请选择时间');
        return false;
      }
      if (!checkDetailAddress(this.info.address)) {
        this.$message.error('详细地址不超过50字');
        return false;
      }
      if (this.info.email !== '') {
        if (!isEmail(this.info.email)) {
          this.$message.error('邮箱不合法');
          return false;
        }
      }
      if (!checkTeamIntro(this.info.profile)) {
        this.$message.error('公司介绍不超过500字');
        return false;
      }
      this.modifyLoading = true;
      console.log(this.info.id);
      API.modify({
        teamId: this.info.id,
        teamName: this.info.cName,
        province: this.info.province,
        city: this.info.city,
        area: this.info.area,
        time: this.time,
        address: this.info.address,
        email: this.info.email,
        profile: this.info.profile,
      })
        .then((res) => {
          this.$message({
            message: res.message.message,
            // showClose: true,
            type: 'success',
          });
          this.$refs.timerbtn.start();
          this.modifyLoading = false;
        })
        .catch(() => {
          this.modifyLoading = false;
        });
      return undefined;
    },
    uploadLogo() {
      this.visible.logoDialog = true;
    },
    handleChange(file, fileList) {
      let flag = true;
      if (!file.name) {
        flag = false;
      }
      const namesplits = file.name.split('.');
      if (namesplits.length !== 2) {
        flag = false;
      }
      const checkList = ['jpg', 'png'];
      if (checkList.indexOf(namesplits[1].toLowerCase()) === -1) {
        flag = false;
      }
      if (!flag) {
        fileList.splice(0, 1);
        return this.$message.error('文件名有误');
      }
      const isGt500kb = file.size / 1024 > 500;
      if (isGt500kb) {
        fileList.splice(0, 1);
        return this.$message.error('文件不能超过500kb');
      }
      this.entity.files = fileList;
      return undefined;
    },
    uploadFile(file) {
      this.formData.append('files', file.file);
    },
    handleExceed() {
      this.$message.warning('最多只能上传1个文件，请删除原文件后再重新上传');
    },
    handleRemove() {
      this.entity.files = [];
      this.$refs.upload.clearFiles();
    },
    handleClose() {
      this.entity.files = [];
      this.$refs.upload.clearFiles();
      this.visible.logoDialog = false;
    },
    uploadInvoice() {
      if (this.entity.files.length < 1) {
        return this.$message.error('请上传企业Logo');
      }
      this.formData = new FormData();
      this.$refs.upload.submit();
      this.modifyLoading = true;
      API.uploadLogo(this.formData)
        .then((response) => {
          this.$message.success('上传成功');
          this.$refs.upload.clearFiles();
          this.visible.logoDialog = false;
          this.entity.files = [];
          this.modifyLoading = false;
          this.$store.commit('user/SET_LOGO', response.data);
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
        });
      return undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
//.page-container {
//  padding: 40px;
//}
//.section {
//  padding: 30px 40px;
//}
.page-title {
  font-size: 20px;
}
::v-deep .input-item .el-input__inner {
  padding: 0 50px;
}
</style>
